import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/Layout/Layout.vue";
import store from "@/store/index";
import { Message } from "element-ui";
import { isRelogin } from "@/utils/request";
import { getToken } from "@/utils/auth";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const whiteList = ["/login", "/index", "/auth-redirect", "/bind", "/register"];

NProgress.configure({ showSpinner: false });

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/", // 首页
    redirect: "index",
    component: Layout,
    children: [
      {
        path: "index",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
        meta: { headerColor: "#121212", title: "金东云 让一切超乎想象" },
      },
    ],
  },
  {
    path: "/collect", // 收藏夹
    component: Layout,
    children: [
      {
        name: "Collect",
        path: "",
        component: () =>
          import(/* webpackChunkName: "collect" */ "@/views/collect/index.vue"),
        meta: { headerColor: "#121212", title: "金东云 让一切超乎想象" },
      },
    ],
  },
  {
    path: "/mj", // ai创作
    component: Layout,
    children: [
      {
        path: "",
        name: "MJDrawing",
        component: () =>
          import(/* webpackChunkName: "mjDrawing" */ "@/views/midjourney/index.vue"),
        meta: { headerColor: "#121212", nofooter: true, title: "AI创作" },
      },
    ],
  },
  {
    path: "/square", // ai创作广场
    component: Layout,
    children: [
      {
        path: "",
        name: "Square",
        component: () =>
          import(
            /* webpackChunkName: "mjDrawing" */ "@/views/midjourney/square/index.vue"
          ),
        meta: { headerColor: "#121212", nofooter: true, title: "AI创作广场" },
      },
    ],
  },
  {
    path: "/sd", // ai创作
    component: Layout,
    children: [
      {
        path: "",
        name: "SDDrawing",
        component: () =>
          import(/* webpackChunkName: "sdDrawing" */ "@/views/stableDiffusion/sdCreate.vue"),
        meta: { headerColor: "#121212", nofooter: true, title: "AI创作" },
      },
    ],
  },
  {
    path: "/chat", // 对话
    component: Layout,
    children: [
      {
        path: "",
        name: "Chat",
        component: () =>
          import(/* webpackChunkName: "chat" */ "@/views/chat/index.vue"),
        meta: { headerColor: "#121212", nofooter: true, title: "AI问答" },
      },
    ],
  },
  {
    path: "/agent", // 对话
    component: Layout,
    children: [
      {
        path: "",
        name: "Agent",
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/index.vue"),
        meta: { headerColor: "#121212", nofooter: true, title: "AI助理" },
        children: [
          {
            path: "",
            name: 'AgentList', // 助理列表
            component: () => import(/* webpackChunkName: "agentlist" */ "@/views/agent/myCreation/myCreates.vue"),
            meta: { headerColor: "#121212", nofooter: true, title: "AI助理" },
          },
          {
            path: "create",
            name: 'AgentCreate', // 助理创建
            component: () => import(/* webpackChunkName: "agentcreate" */ "@/views/agent/myCreation/newCreate.vue"),
            meta: { headerColor: "#121212", nofooter: true, title: "AI助理" },
          },
          {
            path: "training",
            name: 'AgentTraining', // 助理修改
            component: () => import(/* webpackChunkName: "agenttraining" */ "@/views/agent/myCreation/reTraining.vue"),
            meta: { headerColor: "#121212", nofooter: true, title: "AI助理" },
          },
          {
            path: "qna",
            name: 'AgentQna', // 助理问答
            component: () => import(/* webpackChunkName: "agentqna" */ "@/views/agent/qnaRecord/index.vue"),
            meta: { headerColor: "#121212", nofooter: true, title: "AI助理" },
          }
        ]
      },
    ],
  },
  {
    path: "/calculator-main", // 匡算助手
    component: Layout,
    children: [
      {
        name: "CalculatorMain",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "calculatormain" */ "@/views/calculatorMain/index.vue"
          ),
        meta: { headerColor: "#121212", nofooter: true, title: "匡算助手" },
      },
    ],
  },
  {
    path: "/calculator", // 匡算助手
    component: Layout,
    children: [
      {
        name: "Calculator",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "calculator" */ "@/views/calculator/index.vue"
          ),
        meta: { headerColor: "#121212", nofooter: true, title: "匡算助手" },
      },
    ],
  },
  {
    path: "/calculator-2", // 匡算助手
    component: Layout,
    children: [
      {
        name: "Calculator2",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "calculator2.0" */ "@/views/calculator2.0/index.vue"
          ),
        meta: { headerColor: "#121212", nofooter: true, title: "匡算助手" },
      },
    ],
  },
  {
    path: "/companyProfile", // 公司资料
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "companyProfile" */ "@/views/company/index.vue"
          ),
        meta: { headerColor: "#121212", nofooter: true, title: "公司资料" },
      },
    ],
  },
  {
    path: "/standardization", // 找标准化
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "standardization" */ "@/views/standardization/index.vue"
          ),
        meta: { headerColor: "#121212", title: "找标准化" },
      },
    ],
  },
  {
    path: "/standardization/:displayId", // 找标准化详情
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "standardization-id" */ "@/views/standardization/detail.vue"
          ),
        meta: { headerColor: "#121212", title: "找标准化" },
      },
    ],
  },
  {
    path: "/inspiration", // 找灵感
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "inspiration" */ "@/views/inspiration/index.vue"
          ),
        meta: { headerColor: "#121212", title: "找灵感" },
      },
    ],
  },
  {
    path: "/inspiration/detail/:caseId", // 找灵感 案例详情页
    component: Layout,
    children: [
      {
        name: "InspirationDetails",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "inspirationDetails" */ "@/views/inspiration/detail.vue"
          ),
        meta: { headerColor: "#121212", title: "找灵感" },
      },
    ],
  },
  {
    path: "/activity/detail/:caseId", // 活动详情页
    component: Layout,
    children: [
      {
        name: "ActivityDetails",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "activityDetails" */ "@/views/activity/detail.vue"
          ),
        meta: { headerColor: "#121212", title: "活动详情" },
      },
    ],
  },
  {
    path: "/inspiration/detail/:caseId/item/:fileId", // 找灵感 文件详情页
    redirect: "",
    component: Layout,
    children: [
      {
        name: "InspirationFileDetails",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "inspirationFileDetails" */ "@/views/inspiration/imageDetail.vue"
          ),
        meta: { headerColor: "#121212", title: "找灵感" },
      },
    ],
  },
  {
    path: "/activity/detail/:caseId/item/:fileId", // 找灵感 文件详情页
    redirect: "",
    component: Layout,
    children: [
      {
        name: "ActivityFileDetails",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "activityFileDetails" */ "@/views/activity/imageDetail.vue"
          ),
        meta: { headerColor: "#121212", title: "找灵感" },
      },
    ],
  },
  {
    path: "/materials", // 找材料
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "materials" */ "@/views/materials/index.vue"
          ),
        meta: { headerColor: "#121212", title: "找材料" },
      },
    ],
  },

  {
    path: "/materials/:moduleName/item/:materialId", // 找材料 材料详情页
    component: Layout,
    children: [
      {
        name: "MaterialDetails",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "materialDetails" */ "@/views/materials/detail.vue"
          ),
        meta: { headerColor: "#121212", title: "找材料" },
      },
    ],
  },
  {
    path: "/cost", // 查成本
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "cost" */ "@/views/cost/index.vue"),
        meta: { headerColor: "#121212", title: "查成本" },
      },
    ],
  },
  {
    path: "/form", // 找表单
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "form" */ "@/views/form/index.vue"),
        meta: { headerColor: "#121212", title: "找表单" },
      },
    ],
  },
  {
    path: "/course", // 学习
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "course" */ "@/views/course/index.vue"
          ),
        meta: { headerColor: "#121212", title: "学习" },
      },
    ],
  },
  {
    path: "/course/:courseId", // 学习
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "course-detail" */ "@/views/course/detail.vue"
          ),
        meta: { headerColor: "#121212", title: "学习" },
      },
    ],
  },
  {
    path: "/login", // 登录
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/login.vue"),
        meta: { headerColor: "#121212", title: "金东云 让一切超乎想象" },
      },
    ],
  },
  {
    path: "/downAvailable", // 个人中心
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "downAvailable" */ "@/views/downPage/index.vue"
          ),
        meta: { headerColor: "#121212", title: "个人中心" },
      },
    ],
  },
  {
    path: "/userProfile", // 个人中心
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "userProfile" */ "@/views/user/userProfile.vue"
          ),
        meta: { headerColor: "#121212", title: "个人中心", nofooter: true },
      },
    ],
  },

  {
    path: "/office/:officeType/:fileId", // office预览
    meta: { title: "文件预览" },
    component: () =>
      import(
        /* webpackChunkName: "office" */ "@/views/office/officePreview.vue"
      ),
  },
  {
    path: "/globalsearch",
    meta: { title: "搜索结果" },
    component: Layout,
    children: [
      {
        path: "",
        name: "GlobalSearch",
        component: () =>
          import(
            /* webpackChunkName: "globalsearch" */ "@/views/globalSearch/index.vue"
          ),
      },
    ],
  },
  {
    path: "/feedback", // office预览
    meta: { title: "意见反馈" },
    component: Layout,
    children: [
      {
        name: "Feedback",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "feedback" */ "@/views/feedback/index.vue"
          ),
      },
    ],
  },
  {
    path: "/calculator/list", // office预览
    meta: { title: "匡算记录" },
    component: Layout,
    children: [
      {
        name: "CalculatorList",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "calculatorList" */ "@/views/calculatorRecords/index.vue"
          ),
      },
    ],
  },
  {
    path: "/calculator/:id", // office预览
    meta: { title: "匡算记录" },
    component: Layout,
    children: [
      {
        name: "CalculatorItem",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "calculatorItem" */ "@/views/calculatorRecords/view.vue"
          ),
      },
    ],
  },
  {
    path: "/calculator-main/:id", // office预览
    meta: { title: "匡算记录" },
    component: Layout,
    children: [
      {
        name: "CalculatorMainItem",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "CalculatorMainItem" */ "@/views/calculatorRecords/view2.0/index.vue"
          ),
      },
    ],
  },
  {
    path: "/forum", // 论坛
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "forum" */ "@/views/forum/index.vue"),
        meta: { headerColor: "#121212", title: "金东云论坛", nofooter: true },
      },
    ],
  },
  {
    path: "/forum/detail/:id", // 论坛详情
    component: Layout,
    children: [
      {
        name: "ContentsDetail",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "contentsDetail" */ "@/views/forum/detail.vue"
          ),
        meta: { headerColor: "#121212", title: "金东云论坛", nofooter: true },
      },
    ],
  },
  {
    path: "/forum/post", // 论坛详情
    component: Layout,
    children: [
      {
        name: "ContentsPost",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "contentsPost" */ "@/views/forum/post.vue"
          ),
        meta: { headerColor: "#121212", title: "发帖子" },
      },
    ],
  },
  {
    path: "/forum/article", // 论坛详情
    component: Layout,
    children: [
      {
        name: "ContentsArticle",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "contentsArticle" */ "@/views/forum/article.vue"
          ),
        meta: { headerColor: "#121212", title: "写文章" },
      },
    ],
  },
  {
    path: "/computer", // 计算器
    component: Layout,
    children: [
      {
        name: "Drawing",
        path: "",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/computer/index.vue"),
        meta: { headerColor: "#121212", title: "计算器", nofooter: true },
      },
    ],
  },
  {
    path: "/", // ai ppt
    redirect: "aipowerpoint",
    component: Layout,
    children: [
      {
        path: "aipowerpoint",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/aipowerpoint/index.vue"
          ),
        meta: { headerColor: "#121212", title: "PPT", nofooter: true },
      },
    ],
  },
  {
    path: "/", // ai ppt
    redirect: "publish-model",
    component: Layout,
    children: [
      {
        path: "publish-model",
        component: () =>
          import(
            /* webpackChunkName: "publish-model" */ "@/views/stableDiffusion/model/publishModel.vue"
          ),
        meta: { headerColor: "#121212", title: "模型发布", nofooter: true },
      },
    ],
  },
  {
    path: "/", // ai ppt
    redirect: "sd-allmodel",
    component: Layout,
    children: [
      {
        path: "sd-allmodel",
        component: () =>
          import(
            /* webpackChunkName: "sd-allmodel" */ "@/views/stableDiffusion/myModel/allModel.vue"
          ),
        meta: { headerColor: "#121212", title: "模型发布", nofooter: true },
      },
    ],
  },

  {
    path: "/ai-video", // ai视频
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "forum" */ "@/views/video/index2.vue"),
        meta: { headerColor: "#121212", title: "创短片", nofooter: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (getToken()) {
    if (to.path === "/login") {
      Reflect.deleteProperty(to.query, "code");
      next();
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true;
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch("GetInfo")
          .then(() => {
            isRelogin.show = false;
            // next({ ...to })
            next();
            NProgress.done();
          })
          .catch((err) => {
            Message("无效的会话，或者登录已过期，请先登录");
            console.log(2, to);
            Reflect.deleteProperty(to.query, "code");
            store.dispatch("LogOut").then(() => {
              next({ path: "/login" });
              NProgress.done();
            });
          });
      } else {
        next();
        NProgress.done();
      }
    }
  } else {
    if (to.query.code) {
      try {
        console.log(3, to);
        await store.dispatch("WxLogin", to.query.code);
        store.dispatch("GetInfo").then(() => {
          isRelogin.show = false;
          for (let key in to.query) {
            Reflect.deleteProperty(to.query, key);
          }
          next();
          NProgress.done();
        });
      } catch (e) {
        for (let key in to.query) {
          Reflect.deleteProperty(to.query, key);
        }
        next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
        NProgress.done();
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        // 在免登录白名单，直接进入
        next();
        NProgress.done();
      } else {
        Message("无效的会话，或者登录已过期，请先登录");
        for (let key in to.query) {
          Reflect.deleteProperty(to.query, key);
        }
        next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
        NProgress.done();
      }
    }
  }
});

export default router;
